
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AdminAuthGuardService } from './services/admin-auth-guard.service';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent, children: [
            {
                path: '', loadChildren: './pages/dashboard/dashboard.module#DashboardModule',
                data: { breadcrumb: 'Dashboard' },
                canActivate: [AuthGuardService]
            },
            {
                path: 'configurations', loadChildren: './pages/configurations-tabs/configurations-tabs.module#ConfigurationsTabsModule',
                data: { breadcrumb: 'configurations' }
            },
            {
                path: 'changePassword', loadChildren: './pages/change-password/change-password.module#ChangePasswordModule',
                data: { breadcrumb: 'Change Password' }
            },
            // tslint:disable-next-line:max-line-length
            {
                path: 'ManageClientEntityUser', loadChildren: './pages/manage-client-entity-user/manage-client-entity-user.module#ManageClientEntityUserModule'
                , data: { breadcrumb: 'Users',permittedRoles: ['ManageAdminUsers'] }
                ,canActivate: [AuthGuardService]
            },
            {
                path: 'EditClientEntityUser', loadChildren: './pages/manage-client-entity-user/edit-client-entity-user/edit-client-entity-user.module#EditClientEntityUserModule',
                data: { breadcrumb: 'Edit Users',permittedRoles: ['ManageAdminUsers'] }
                ,canActivate: [AuthGuardService]
            },
            //alaa
            {
                path: 'ManageCommunityUnit', loadChildren: './pages/manage-community-unit/manage-community-unit.module#ManageCommunityUnitModule',
                data: { breadcrumb: 'Units', permittedRoles: ['ManageUnits'] }
                ,canActivate: [AuthGuardService]
            },
            // tslint:disable-next-line:max-line-length
            {
                path: 'ManageOwner', loadChildren: './pages/manage-owner/manage-owner.module#ManageOwnerModule',
                data: { breadcrumb: 'Manage Owner', permittedRoles: ['ManageOwner'] }
                ,canActivate: [AuthGuardService]
            },
            {
                path: 'ManageExplore', loadChildren: './pages/manage-explore/manage-explore.module#ManageExploreModule',
              data: { breadcrumb: 'Manage Explore' ,permittedRoles: ['ManageExplore']},
              canActivate: [AuthGuardService]
            },
            {
                path: 'EditOwner', loadChildren: './pages/manage-owner/edit-owner/edit-owner.module#EditOwnerModule',
                data: { breadcrumb: 'Edit owner',permittedRoles: ['ManageOwner'] }
                ,canActivate: [AuthGuardService]
            },
            {
                path: 'AddOwner', loadChildren: './pages/manage-owner/add-owner/add-owner.module#AddOwnerModule',
                data: { breadcrumb: 'Add owner',permittedRoles: ['ManageOwner'] }
                ,canActivate: [AuthGuardService]
            },
            {
                path: 'ManageBroadcast', loadChildren: './pages/broadcast/broadcast.module#BroadcastModule',
                data: { breadcrumb: 'Manage Broadcast',permittedRoles: ['ManageBroadcast'] }
                ,canActivate: [AuthGuardService]
            },

            {
                path: 'ManagePhase', loadChildren: './pages/manage-category/manager-category.module#ManagerCategoryModule',
                data: { breadcrumb: 'Phase' ,permittedRoles: ['ManagePhases']}
                ,canActivate: [AuthGuardService]
            },
            {
                path: 'ManageRegistrationRequest', loadChildren: './pages/manage-reg-request/mange-reg-request.module#ManageregrequestModule',
                data: { breadcrumb: ' Manage regrequest' ,permittedRoles: ['ManageRegistrationRequest']}
                ,canActivate: [AuthGuardService]
            },
            {
                path: 'ManageTicket', loadChildren: './pages/manage-ticket/manage-ticket.module#ManageTicketModule',
                data: { breadcrumb: 'Services',permittedRoles: ['ManageTicket']  }
                ,canActivate: [AuthGuardService]
            }, 
            {
                path: 'EditTicket', loadChildren: './pages/manage-ticket/edit-ticket/edit-ticket.module#EditTicketModule',
                data: { breadcrumb: 'Service Details',permittedRoles: ['ManageTicket']  }
                ,canActivate: [AuthGuardService]
            },
            {
                path: 'ForbiddenPage', loadChildren: './pages/forbidden-page/forbidden-page.module#ForbiddenPageModule',
                data: { breadcrumb: 'Forbidden Page' },
            },
            {
                path: 'ManagePredefinedTicketReply', loadChildren: './pages/manage-predefined-ticket-reply/manage-predefined-ticket-reply.module#ManagePredefinedTicketReplyModule',
                data: {breadcrumb:'Custom  Replies',permittedRoles: ['ManagePreDefinedTicketReply']}
                ,canActivate: [AuthGuardService]
            },
            {
                path: 'ManageUnitType', loadChildren: './pages/manage-unit-type/manage-unit-type.module#ManageUnitTypeModule',
                data: {breadcrumb:'Unit Types',permittedRoles: ['ManageUnitTypes']}
                ,canActivate: [AuthGuardService]
            },
            {
                path: 'ContactUs', loadChildren: './pages/contact-us/contact-us.module#ContactUsModule',
                data: { breadcrumb: 'ContactUs Page' },
            },

             {
                 path: 'actionlogreport', loadChildren: './pages/actionlogreport/actionlogreport.module#actionlogreportModule',
                 data: { breadcrumb: 'action log report',permittedRoles: ['ManageReports'] },
                 canActivate: [AuthGuardService]
             },

             {
                path: 'gateLogreport', loadChildren: './pages/gateLogreport/gateLogreport.module#gateLogreportModule',
                data: { breadcrumb: 'Gate log report',permittedRoles: ['ManageReports'] },
                canActivate: [AuthGuardService]  
            },
            {
                path: 'OwnerReport', loadChildren: './pages/OwnerReport/OwnerReport.module#OwnerReportModule',
                data: { breadcrumb: 'Owner Report ',permittedRoles: ['ManageReports'] },
                canActivate: [AuthGuardService]  
            },
            {
                path: 'NotPaidInvoiceReport', loadChildren: './pages/NotPaidInvoiceReport/NotPaidInvoiceReport.module#NotPaidInvoiceReportModule',
                data: { breadcrumb: 'NotPaid Claim Report',permittedRoles: ['ManageReports'] },
                canActivate: [AuthGuardService]  
            },
            {
                path: 'PaidInvoiceReport', loadChildren: './pages/PaidInvoiceReport/PaidInvoiceReport.module#PaidInvoiceReportModule',
                data: { breadcrumb: 'Paid Claim Report',permittedRoles: ['ManageReports'] },
                canActivate: [AuthGuardService]  
            },
            {
                path: 'DetailedInvoiceReport', loadChildren: './pages/DetailedInvoiceReport/DetailedInvoiceReport.module#DetailedInvoiceReportModule',
                data: { breadcrumb: 'Detailed Claim Report',permittedRoles: ['ManageReports'] },
                canActivate: [AuthGuardService]  
            },
            {
                path: 'ImportData', loadChildren: './pages/import-data/import-data.module#ImportDataModule',
                data: { breadcrumb: 'Import Data',permittedRoles: ['ImportData'] }
                ,canActivate: [AuthGuardService]
            },
            {
                path: 'ResortRulesAndPolicy', loadChildren: './pages/resort-rules-and-policy/resort-rules-and-policy.module#ResortRulesAndPolicyModule',
                data: { breadcrumb: 'Resort Rules And Policy',permittedRoles: ['CommunityPolicy'] }
                ,canActivate: [AuthGuardService]
            },
            {
                path: 'ManageInvoice', loadChildren: './pages/manage-Invoice/manage-Invoice.module#ManageInvoiceModule',
                data: { breadcrumb: 'Manage Claim' ,permittedRoles: ['ManageInvoice']}
                ,canActivate: [AuthGuardService]
            },
            {
                path: 'ImportInvoice', loadChildren: './pages/import-invoice/import-invoice.module#ImportInvoiceModule',
                data: { breadcrumb: 'Import Claim' ,permittedRoles: ['ManageInvoice']}
                ,canActivate: [AuthGuardService]
            },
        ]
    },
    { path: 'login', loadChildren: './pages/login/login.module#LoginModule' },
    { path: 'forgetPassword', loadChildren: './pages/forget-password/forget-password.module#ForgetPasswordModule' },

    // {
    //     path: 'actionlogreport', loadChildren: './pages/actionlogreport/actionlogreport.module#actionlogreportModule',
       
    // },

    {
        path: 'reset', loadChildren: './pages/reset-password/reset-password.module#ResetPasswordModule',
        canActivate: [AdminAuthGuardService]
    },
    { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
    { path: '**', component: NotFoundComponent },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
    // useHash: true
});
